<template>
  <section class="domains-area">
    <VxCollapsableList :data="hosts" ref="collapsable" @filter="filter" @toggle-item="toggleCollaspe">
      <template v-slot:header="{ item }">
        <div class="flex justify-between items-center">
          <div class="flex items-center">
            <img v-if="item.host.favIconPath" :src="item.host.favIconPath" class="rounded-full w-8 h-8 border-solid border border-grey-light mr-3" />
            <img v-else src="@/assets/images/svg/http.svg" class="rounded-full w-8 h-8 border-solid border border-grey-light mr-3" />
            <h6 class="font-bold">{{ item.host | urlPath }}</h6>
          </div>
          <div class="flex items-center mr-base">
            <p class="font-light text-xs max-w-xs truncate">
              <vs-icon icon-pack="feather" icon="icon-camera" class="mr-1 text-xs"></vs-icon>
              {{ item.summary.captures }}
            </p>
            <p class="font-light text-xs max-w-xs truncate ml-5" v-if="item.summary.lastCapture">
              <vs-icon icon-pack="feather" icon="icon-clock" class="mr-1 text-xs"></vs-icon>
              {{ item.summary.lastCapture.dateCaptured | moment }}
            </p>
          </div>
        </div>
      </template>
      <template v-slot:content="{ item, index, data }">
        <vx-data-list
          class="vx-data-list-captures"
          v-show="data"
          :ref="`vxDataList_${index}`"
          :hoverFlat="true"
          :data="data[item.host.id] || []"
          :columns="columns"
          :search="false"
          :config="{
            disablePager: true,
            selectable: false
          }"
          @filter="filterCapturesByDomain(item.host.id, index)"
        />
      </template>
    </VxCollapsableList>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import CaptureThumbnail from '@/views/captures/components/CaptureThumbnail';
import Paging from '@/entity/Paging';
export default {
  name: 'CapturesDomains',
  data() {
    return {
      contents: {},
      columns: [
        {
          component: CaptureThumbnail
        }
      ]
    };
  },
  computed: {
    keys() {
      if (this.$refs) console.log(this.$refs);
      return Object.keys(this.$refs);
    },
    ...mapState('captures', ['hosts', 'list']),
    getImgSrc() {
      return item => {
        try {
          const array = item.screenshots;
          for (let i = 0; i < array.length; i++) {
            const screenshot = array[i];
            const image = screenshot.image;
            if (image.thumbnail || image.large) return image.thumbnail || image.large;
          }
          return false;
        } catch (e) {
          console.error(e);
          return false;
        }
      };
    }
  },
  methods: {
    async fetchCapturesById(id, { paging } = {}) {
      const list = await this.$store.dispatch('captures/list', {
        dontCommit: true,
        params: {
          'url.host.id': id
        },
        paging: paging || new Paging()
      });
      return list;
    },
    async filterCapturesByDomain(id, index) {
      const item = this.$refs[`vxDataList_${index}`];
      const result = await this.fetchCapturesById(id, {
        paging: item.paging
      });
      this.$refs.collapsable.setContents(id, result);
    },
    async fetch({ paging, query } = {}) {
      await this.$store.dispatch('captures/hosts', {
        dontCommit: true,
        params: {
          has: 'captures',
          ...(query ? { query } : {})
        },
        paging: paging || new Paging()
      });
    },
    filter(paging) {
      console.log({ paging });
      this.fetch({ paging });
    },
    /* eslint no-unused-vars: 0 */
    async toggleCollaspe({ item, index, done }) {
      const { id } = item.host;
      const result = await this.fetchCapturesById(id);
      done(id, result);
    }
  },
  async created() {
    // await this.$store.dispatch('captures/hosts', { params: { has: 'captures' } });
    await this.fetch();
  },
  mounted() {},
  components: {}
};
</script>
