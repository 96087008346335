var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"domains-area"},[_c('VxCollapsableList',{ref:"collapsable",attrs:{"data":_vm.hosts},on:{"filter":_vm.filter,"toggle-item":_vm.toggleCollaspe},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex justify-between items-center"},[_c('div',{staticClass:"flex items-center"},[(item.host.favIconPath)?_c('img',{staticClass:"rounded-full w-8 h-8 border-solid border border-grey-light mr-3",attrs:{"src":item.host.favIconPath}}):_c('img',{staticClass:"rounded-full w-8 h-8 border-solid border border-grey-light mr-3",attrs:{"src":require("@/assets/images/svg/http.svg")}}),_c('h6',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm._f("urlPath")(item.host)))])]),_c('div',{staticClass:"flex items-center mr-base"},[_c('p',{staticClass:"font-light text-xs max-w-xs truncate"},[_c('vs-icon',{staticClass:"mr-1 text-xs",attrs:{"icon-pack":"feather","icon":"icon-camera"}}),_vm._v(" "+_vm._s(item.summary.captures)+" ")],1),(item.summary.lastCapture)?_c('p',{staticClass:"font-light text-xs max-w-xs truncate ml-5"},[_c('vs-icon',{staticClass:"mr-1 text-xs",attrs:{"icon-pack":"feather","icon":"icon-clock"}}),_vm._v(" "+_vm._s(_vm._f("moment")(item.summary.lastCapture.dateCaptured))+" ")],1):_vm._e()])])]}},{key:"content",fn:function(ref){
var item = ref.item;
var index = ref.index;
var data = ref.data;
return [_c('vx-data-list',{directives:[{name:"show",rawName:"v-show",value:(data),expression:"data"}],ref:("vxDataList_" + index),staticClass:"vx-data-list-captures",attrs:{"hoverFlat":true,"data":data[item.host.id] || [],"columns":_vm.columns,"search":false,"config":{
          disablePager: true,
          selectable: false
        }},on:{"filter":function($event){return _vm.filterCapturesByDomain(item.host.id, index)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }